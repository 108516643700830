<template>
  <div v-if="openFilter" ref="filterButton" v-click-outside="closeFilter" class="filter">
    <div class="heading">
      <div class="title">Filter</div>
      <div class="options">
        <div @click="sendFilter" class="btn-main">Apply</div>
        <div @click="resetFilter" class="reset">Reset</div>
      </div>
    </div>

    <div v-if="showAmountFilter" class="filters">
      <div class="heading">
        <div class="filter-name">
          <svg
            @click="showAmountIssued($event)"
            ref="amountissuedarrow"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 15L12 9L18 15"
              stroke="#6E737E"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span>Amount Issued</span>
        </div>
      </div>
      <div v-if="showAmountOptions" class="filter-options">
        <div class="amount-input">
          <div>
            <div>Min Amount</div>
            <a-input-number
              v-model:value="filterData.min_amount"
              style="width: 150px"
            />
          </div>

          <div>
            <div>Max Amount</div>
            <a-input-number
              v-model:value="filterData.max_amount"
              style="width: 150px"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="showDateFilter" class="filters">
      <div class="heading">
        <div class="filter-name">
          <svg
            @click="openDateFilter($event)"
            ref="dateFilterArrow"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 15L12 9L18 15"
              stroke="#6E737E"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span>Date Filter</span>
        </div>
      </div>
      <div v-if="showDates" class="filter-options">
        <div class="amount-input">
          <div>
            <div>Start Date</div>
            <a-date-picker
              v-model:value="filterData.start_date"
              format="YYYY-MM-DD"
            />
          </div>

          <div>
            <div>End Date</div>
            <a-date-picker
              v-model:value="filterData.end_date"
              format="YYYY-MM-DD"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="showStatusFilter"  class="filters">
      <div class="heading">
        <div class="filter-name">
          <svg
            @click="showStatus($event)"
            ref="statusarrow"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 15L12 9L18 15"
              stroke="#6E737E"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span>Status</span>
        </div>
      </div>
       
      <div v-if="showStatusOptions" class="filter-options">
        <div v-for="(status, index) in status" :key="index">
          <input
            type="radio"
            v-model="filterData.status"
            :value="status.value"
            @click="sendStatus($event)"
          />
          <span>{{status.name}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Filter",
  data() {
    return {
      showAmountOptions: true,
      showDates: true,
      showStatusOptions: true,
      filterData: {
        start_date: "",
        end_date: "",
        min_amount: "",
        max_amount: "",
        status: "",
      },
    };
  },

  props: {
    openFilter: {
      type: Boolean,
      required: true,
    },

    showAmountFilter: {
      type: Boolean,
      default: false,
      required: true,
    },

    showDateFilter: {
      type: Boolean,
      default: false,
      require: true
    },

    showStatusFilter: {
      type: Boolean,
      default: false,
      required: true
    },

    status: {
      type: Array,
      require: true,
    }
  },

  methods: {
    sendStatus(e) {
      this.status = e.target.value;
    },

    closeFilter(e, event) {
      if (
        event.target === this.$store.state.filterButton ||
        this.$store.state.filterButton.contains(event.target) ||
        event.target.className.match("ant-picker")
      ) {
        return;
      } else {
        this.$emit("hideFilter");
      }

    },

    sendFilter() {
      this.$emit("getFilters", this.filterData);
    },

    resetFilter() {
      const data = {
        min_amount: "",
        max_amount: "",
        start_date: "",
        end_date: "",
        status: "",
      };

      this.filterData = data;

      this.$emit("clearFilter", this.filterData);
    },

    showAmountIssued(e) {
      this.$refs.amountissuedarrow.classList.toggle("rotate");
      this.showAmountOptions = !this.showAmountOptions;
    },

    openDateFilter(e) {
      this.$refs.dateFilterArrow.classList.toggle("rotate");
      this.showDates = !this.showDates;
    },
    showStatus(e) {
      this.$refs.statusarrow.classList.toggle("rotate");
      this.showStatusOptions = !this.showStatusOptions;
    },
  },
};
</script>

<style lang="scss" scoped>
.filter {
  position: absolute;
  right: 20px;
  margin-top: 20px;
  background: #fff;
  width: 450px;
  font-size: 15px;
  border-radius: 10px;
  padding: 20px;
  z-index: 999;
  box-shadow: 2px 2px 10px rgba(146, 150, 158, 0.15);

  .heading {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    border-bottom: 1px solid #dfe3ec;

    .filter-name {
      display: flex;

      .rotate {
        transform: rotate(180deg);

        /* Safari */
        -webkit-transform: rotate(180deg);

        /* Firefox */
        -moz-transform: rotate(180deg);

        /* IE */
        -ms-transform: rotate(180deg);

        /* Opera */
        -o-transform: rotate(180deg);
      }

      span {
        margin-left: 10px;
      }
    }

    .title {
      font-size: 19px;
      padding-top: 10px;
    }
    .options {
      display: flex;
      justify-content: space-between;
      width: 150px;
      span {
        padding-top: 10px;
      }

      .reset {
        color: #aa8358;
        position: relative;
        top: 9px;
        cursor: pointer;
      }

      .btn-main {
        width: 90px;
        padding: 9px 0;
      }
    }

    @include respond-to(tablet) {
      display: grid;
    }
  }

  .filters {
    margin-top: 20px;

    .heading {
      border: 0;
    }

    .filter-options {
      background: #f9f9f9;
      padding: 20px;

      div {
        margin-bottom: 10px;

        span {
          margin-left: 10px;
        }
      }

      .amount-input {
        display: flex;
        justify-content: space-between;
        width: 100%;

        @include respond-to(tablet) {
          display: grid;
        }
      }
    }
  }

  @include respond-to(mobile) {
    width: 250px;
  }

  @include respond-to(tablet) {
    right: revert;
  }
}
</style>
