import Api from "../Api";

export default {
  namespaced: true,

  state: {
    loanStats: "",
    disbursementData: "",
    loanDetails: "",
    applicantDetails: "",
    applicantCancelledLoans: [],
    applicantPaidLoans: [],
    request: {
      message: "",
      status: "",
    },
  },

  mutations: {
    SET_REQUEST(state, request) {
      state.request = request;
    },

    SET_LOANS(state, payload) {
      state.loans = payload;
    },

    SET_LOANS_STATS(state, payload) {
      state.loanStats = payload;
    },

    SET_DISBURSEMENT_DATA(state, payload) {
      state.disbursementData = payload;
    },

    SET_LOAN_DETAILS(state, payload) {
      state.loanDetails = payload;
    },

    SET_APPLICANT_DETAILS(state, payload) {
      state.applicantDetails = payload;
    },

    APPLICANT_CANCELLED_LOANS(state, payload) {
      state.applicantCancelledLoans = payload;
    },

    APPLICANT_PAID_LOANS(state, payload) {
      state.applicantPaidLoans = payload;
    },
  },

  actions: {
    // Applications //

    async fetchLoan({ commit }, payload) {
      try {
        const { data } = await Api().get(`loans/${payload.id}`);

        commit("SET_LOAN_DETAILS", data.data);
      } catch (error) {}
    },

    async fetchLoans({}, payload) {
      return new Promise((resolve, reject) => {
        try {
          let query_params = "?";

          if (payload) {
            query_params = `?min_amount=${payload.min_amount}&max_amount=${
              payload.max_amount
            }&start_date=${payload.start_date}&end_date=${
              payload.end_date
            }&search_fields=first_name,last_name,email&search=${
              payload.query
            }&page=${payload.page}&limit=${payload?.limit || 10}`;
          }

          if (payload.status !== "")
            query_params = `${query_params}&status=${payload.status}`;

          const response = Api().get(
            `loans${query_params == "?" ? "" : query_params}`
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },

    async fetchLoanStats({ commit }) {
      try {
        const { data } = await Api().get("loans/stats");
        commit("SET_LOANS_STATS", data.data);
      } catch (error) {}
    },

    async fetchApplicantDetails({ commit }, payload) {
      try {
        const { data } = await Api().get(`loans?user_id=${payload.userId}`);
        commit("SET_LOANS", data.data.loans);
        commit("SET_APPLICANT_DETAILS", data.data.loans[0].user);
      } catch (error) {}
    },

    // Fetch Paid and Cancelled loans for applicant
    async fetchApplicantLoans({ commit }, payload) {
      try {
        const cancelledLoans = await Api().get(
          `loans?user_id=${payload.userId}&status=cancelled`
        );
        const paidLoans = await Api().get(
          `loans?user_id=${payload.userId}&status=paid`
        );

        commit("APPLICANT_CANCELLED_LOANS", cancelledLoans.data.data.loans);
        commit("APPLICANT_PAID_LOANS", paidLoans.data.data.loans);
      } catch (error) {}
    },

    //Disbursements//

    async fetchDisbursement({ commit }, payload) {
      return new Promise((resolve, reject) => {
        try {
          let query_params = "?";
          if (payload.filter)
            query_params = `${query_params}min_amount=${payload.filter.min_amount}&max_amount=${payload.filter.max_amount}&start_date=${payload.filter.start_date}&end_date=${payload.filter.end_date}&status=${payload.filter.status}&search_fields=first_name,last_name&search=${payload.filter.query}`;
          const response = Api().get(
            `loans/disbursements/${payload.id}${
              query_params === "?" ? "" : query_params
            }`
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },

    async fetchDisbursements({}, payload) {
      return new Promise((resolve, reject) => {
        try {
          let query_params = "?";

          if (payload) {
            query_params = `?min_amount=${payload.min_amount}&max_amount=${
              payload.max_amount
            }&start_date=${payload.start_date}&end_date=${
              payload.end_date
            }&search_fields=batch_id&search=${payload.query}&page=${
              payload.page
            }&limit=${payload?.limit || 10}`;
          }
          const response = Api().get(
            `loans/disbursements${query_params == "?" ? "" : query_params}`
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },

    async approveDisbursement({ commit }, payload) {
      try {
        const { data } = await Api().patch(
          `loans/disbursements/${payload}/approve`
        );

        const request = {
          message: data.message,
          status: data.status,
        };

        commit("SET_REQUEST", request);
      } catch (error) {
        if (error) {
          const request = {
            message: error.response.data.message,
            status: error.response.data.status,
          };

          commit("SET_REQUEST", request);

          return;
        }
      }
    },

    // Refunds //

    async fetchRefunds({}, payload) {
      return new Promise((resolve, reject) => {
        try {
          let query_params = `?min_amount=${payload.min_amount}&max_amount=${payload.max_amount}&start_date=${payload.start_date}&end_date=${payload.end_date}&search_fields=batch_id&search=${payload.query}&page=${payload.page}`;

          const response = Api().get(`loans/refunds${query_params}&limit=10`);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },

    async fetchRefund({ commit }, payload) {
      return new Promise((resolve, reject) => {
        try {
          let query_params = "?";
          if (payload.filter)
            query_params = `${query_params}min_amount=${payload.filter.min_amount}&max_amount=${payload.filter.max_amount}&start_date=${payload.filter.start_date}&end_date=${payload.filter.end_date}&status=${payload.filter.status}&search_fields=first_name,last_name&search=${payload.filter.query}`;
          const response = Api().get(
            `loans/refunds/${payload.id}${
              query_params === "?" ? "" : query_params
            }`
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },

    async refundLoans({ commit }, payload) {
      try {
        if (payload) {
          const { data } = await Api().patch(`loans/${payload}/refunds`);

          const request = {
            message: data.message,
            status: data.status,
          };

          commit("SET_REQUEST", request);
        }
      } catch (error) {
        if (error) {
          const request = {
            message: error.response.data.message,
            status: error.response.data.status,
          };

          commit("SET_REQUEST", request);

          return;
        }
      }
    },

    async retryDebit({}, payload) {
      return new Promise((resolve, reject) => {
        try {
          const response = Api().get(`loans/debit/${payload.loan_id}`);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },

    async cancelPendingDebits({ commit }, payload) {
      try {
        if (payload) {
          // console.log({ payload });
          // return;
          const { data } = await Api().post(`loans/abort`, payload);

          const request = {
            message: data.message,
            status: data.status,
          };

          commit("SET_REQUEST", request);
        }
      } catch (error) {
        if (error) {
          const request = {
            message: error.response.data.message,
            status: error.response.data.status,
          };

          commit("SET_REQUEST", request);

          return;
        }
      }
    },
  },
};
