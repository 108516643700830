import Api from "../Api";

export default {
	namespaced: true,

	state: {
		defaulters: []
	},

	mutations: {
		SET_DEFAULTERS(state, payload) {
			state.defaulters = payload;
		}
	},

	actions: {
		async fetchDefaulters({ commit }, payload) {
			return new Promise((resolve, reject) => {
				try {
					let query_params = `min_amount=${payload.min_amount}&max_amount=${payload.max_amount}&start_date=${payload.start_date}&end_date=${payload.end_date}&search_fields=first_name,last_name&search=${payload.query}&page=${payload.page}`;

					const response = Api().get(
						`loans?status=past_due&${query_params}&limit=${
							payload?.limit || 10
						}`
					);
					resolve(response);
				} catch (error) {
					reject(error);
				}
			});
		}
	}
};
