<template>
  <div class="setting-title">Admin Settings</div>
  <div class="tabs">
    <div
      v-if="$store.getters['auth/fetchTeam']"
      @click="settingTab = 1"
      :class="[settingTab === 1 ? 'active-tab' : ' ', 'tab']"
    >
      Team
    </div>
    <div
      @click="settingTab = 2"
      :class="[settingTab === 2 ? 'active-tab' : ' ', 'tab']"
    >
      Manage Role
    </div>
    <div
      @click="settingTab = 3"
      :class="[settingTab === 3 ? 'active-tab' : ' ', 'tab']"
    >
      Batch Schedule
    </div>
    <div
      @click="settingTab = 4"
      :class="[settingTab === 4 ? 'active-tab' : ' ', 'tab']"
    >
      Assessment Rule
    </div>
    <div
      @click="settingTab = 5"
      :class="[settingTab === 5 ? 'active-tab' : ' ', 'tab']"
    >
      Credit Scoring Rule
    </div>
  </div>
  <div v-if="settingTab === 1 && $store.getters['auth/fetchTeam']" class="team">
    <Team />
  </div>
  <div v-if="settingTab === 2" class="manage-role">
    <ManageRole />
  </div>
  <div v-if="settingTab === 3" class="batch-schedule">
   <BatchSchedule />
  </div>
  <div v-if="settingTab === 4" class="assessment-rule">
    <AssessmentRule />
  </div>
  <div v-if="settingTab === 5" class="credit-scoring">
    <CreditScoringRule />
  </div>
</template>

<script>
import BatchSchedule from "./BatchSchedule.vue"
import AssessmentRule from "./AssessmentRule.vue";
import CreditScoringRule from "./CreditScoringRule.vue";
import ManageRole from "./ManageRole.vue";
import Team from "./team.vue";
export default {
  name: "Settings",
  components: {
    BatchSchedule,
    ManageRole,
    Team,
    AssessmentRule,
    CreditScoringRule,
  },

  data() {
    return {
      settingTab: 1,
    };
  },
};
</script>
<style lang="scss" scoped>
.tabs {
  display: flex;
  margin-bottom: 50px;
  border-bottom: 2px solid #dfe3ec;
  font-size: 13px;
  color: #92969e;
  .tab {
    margin-right: 30px;
    padding: 0 6px 20px;
    cursor: pointer;
  }
  .active-tab {
    border-bottom: 3px solid #d4a668;
  }

  @include respond-to(tablet) {
    overflow: scroll;
  }
}
.sessions {
  color: #92969e;
  font-size: 13px;
  margin-bottom: 60px;
  span {
    margin-right: 25px;
    .sub {
      margin-right: 17px;
      font-size: 16px;
    }
  }
  .active-session {
    background: #aa8358;
    color: #fff;
    padding: 12px 10px;
    border-radius: 2px;
  }
}
</style>
