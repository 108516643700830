<template>
  <SideBar />

  <div class="body">
    <NavBar />

    <div class="admin-head">
      <div class="dashboard-title">Settings</div>
      <div
        v-if="$store.getters['auth/addTeam']"
        @click="$store.state.showInviteModal = true"
        class="btn"
      >
      <plus-icon size="16" stroke-width="2" />
        Invite Member
      </div>
    </div>
    <div class="admin-settings">
      <div class="options">
        <div @click="optionTab = 1" class="option">
          <div :class="[optionTab === 1 ? 'active-tab' : ' ']">
            <adjustments-alt-icon />
            <span>Admin Settings</span>
          </div>
        </div>
        <div @click="optionTab = 2" class="option">
          <div :class="[optionTab === 2 ? 'active-tab' : ' ']">
            <user-circle-icon />
            <span>Profile</span>
          </div>
        </div>
        <div @click="optionTab = 3" class="option">
          <div :class="[optionTab === 3 ? 'active-tab' : ' ']">
           <shield-lock-icon />
            <span>Security</span>
          </div>
        </div>
      </div>
      <div class="settings">
        <div v-if="optionTab === 3">
          <Security />
        </div>
        <div v-if="optionTab === 2">
          <Profile />
        </div>
        <div v-if="optionTab === 1">
          <Settings />
        </div>
      </div>
    </div>
    <div v-if="$store.state.showInviteModal">
      <InviteModal />
    </div>
  </div>
  <vue-element-loading
    :active="show"
    :is-full-screen="true"
    background-color="rgba(255, 255, 255, .8)"
    spinner="bar-fade-scale"
  />
</template>

<script>
import Settings from "../components/admin/settings.vue";
import Profile from "../components/admin/profile.vue";
import Security from "../components/admin/security.vue";
import InviteModal from "../components/admin/inviteModal.vue";
export default {
  name: "Admin",
  components: {
    Security,
    Profile,
    Settings,
    InviteModal,
  },

  data() {
    return {
      optionTab: 1,
      show: true,
    };
  },

  mounted() {
    this.show = false;
  },
};
</script>

<style lang="scss" scoped>
.admin-head {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;

  .dashboard-title {
    font-size: 22px;
    color: #242424;
    padding-top: 8px;
  }

  .btn {
    font-size: 14px;

    svg {
      position: relative;
      top: 3px;
    }
  }
}
.admin-settings {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  .options {
    padding: 10px 6px 0 0;
    width: 210px;
    font-size: 14px;
    border: 2px solid #dfe3ec;
    border-radius: 7px;
    background: #fff;
    height: 285px;
    cursor: pointer;

    .option {
      border-bottom: 1px solid #dfe3ec;
      padding: 20px 0 20px;

      div {
        padding: 5px 15px;

        @include respond-to(mobile) {
          display: grid;
        }
      }

      .active-tab {
        border-left: 5px solid #d4a668;

        @include respond-to(tablet) {
          border-left: none;
          border-bottom: 5px solid #d4a668;
        }
      }

      svg {
        position: relative;
        top: 6px;

        @include respond-to(mobile) {
          position: relative;
          top: 0;
          margin: 0 auto;
        }
      }
      span {
        margin-left: 15px;
        color: #6e737e;

        @include respond-to(mobile) {
          position: relative;
          top: 0;
          margin: 0 auto;
        }
      }
    }

    @include respond-to(tablet) {
      display: flex;
      justify-content: space-between;
      padding: 10px 6px 0 10px;
      width: 100%;
      height: 95px;
    }
  }
  .settings {
    width: 78%;
    background: #fff;
    border: 2px solid #dfe3ec;
    padding: 30px;
    border-radius: 7px;
    margin-bottom: 50px;

    @include respond-to(tablet) {
      margin-top: 50px;
      width: 100%;
    }

    @include respond-to(mobile) {
      padding: 10px;
    }
  }

  @include respond-to(tablet) {
    flex-direction: column;
  }
}
</style>