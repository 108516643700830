<template>
  <div class="sidebar">
    <div class="logo">
      <img src="../../assets/logo.png" alt="logo" />
    </div>
    <div v-for="(menu, index) in sidebar" :key="index" class="menu">
      <router-link :to="menu.link" class="menu-item" active-class="active">
        <div>
          <img :src="menu.img" alt="" />

          <span>{{ menu.name }}</span>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "SideBar",
  data() {
    return {
      sidebar: [],
    };
  },

  mounted() {
    this.sidebar = [
      {
        name: "Home",
        link: "/dashboard",
        img: require("../../assets/icons/sidebar/home.svg"),
      },

      {
        name: "Applications",
        link: "/applications",
        img: require("../../assets/icons/sidebar/applications.svg"),
      },

      {
        name: "Equity Inflow",
        link: "/equities",
        img: require("../../assets/icons/sidebar/equity.svg"),
      },

      {
        name: "Borrowers",
        link: "/borrowers",
        img: require("../../assets/icons/sidebar/equity.svg"),
      },

      {
        name: "Disbursements",
        link: "/disbursements",
        img: require("../../assets/icons/sidebar/disbursements.svg"),
      },

      {
        name: "Defaults",
        link: "/defaulters",
        img: require("../../assets/icons/sidebar/defaults.svg"),
      },

      {
        name: "Cancellations",
        link: "/cancellations",
        img: require("../../assets/icons/sidebar/cancellations.svg"),
      },

      {
        name: "Refunds",
        link: "/refunds",
        img: require("../../assets/icons/sidebar/refunds.svg"),
      },

      {
        name: "Transactions",
        link: "/transactions",
        img: require("../../assets/icons/sidebar/transactions.svg"),
      },
      {
        name: "Loan Calculator",
        link: "/loan-calculator",
        img: require("../../assets/icons/sidebar/loan-calculator.svg"),
      },
    ];
  },
};
</script>

<style lang="scss">
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 230px;
  height: 100%;
  background: $main-color;
  z-index: 1;
  color: #fff;
  overflow-y: auto;

  a {
    color: #fff;
    text-decoration: none;
  }

  .logo {
    margin-top: 20px;
    margin-left: 20px;

    img {
      @include respond-to(tablet) {
        width: 150px;
      }
    }
  }

  .menu {
    margin-top: 30px;

    .active {
      background: #1c222e;
      border-radius: 0 20px 20px 0;
      border-left: 3px solid #d4a668;
    }

    .menu-item {
      display: flex;
      font-size: 15px;
      margin-right: 30px;
      margin-bottom: 9px;
      padding: 12px 0 12px 30px;
      span {
        position: relative;
        left: 25px;
      }
    }
  }

  @include respond-to(laptop-mini) {
    display: none;
  }
}
</style>
