<template>
	<SideBar />
	<div class="body">
		<NavBar />
		<div class="dashboard-head">
			<div class="dashboard-title">Dashboard</div>
			<div ref="activityBox" @click="openActivityDate" class="dropdown">
				<span>All time</span>
				<chevron-down-icon size="17" />
			</div>
			<div class="date-filter">
				<ActivityDate
					@sendDate="filterDate"
					v-if="showDate"
					@hideActivityDate="showDate = false"
					@openDate="customDate"
				/>
				<DateFilter
					v-if="showCustomDate"
					@filter="customFilter"
					@cancel="showCustomDate = false"
				/>
			</div>
		</div>
		<div class="data-card">
			<div class="card small">
				<div class="name">Total Customers</div>
				<div class="number">{{ stats.total_users || 0 }}</div>
			</div>
			<div
				class="card small cursor-pointer"
				@click="() => navigate('/applications?status=active')"
			>
				<div class="name">Total Loan Disbursed / Active Loans</div>
				<div class="number">
					{{ stats.total_loans_disbursed || 0 }} /
					{{ stats.total_active_loans || 0 }}
				</div>
			</div>
			<div
				class="card small cursor-pointer"
				@click="() => navigate('/equities')"
			>
				<div class="name">Total Amount Collected</div>
				<div class="number">
					{{
						stats.total_amount_collected?.toLocaleString("en-NG", {
							style: "currency",
							currency: "NGN"
						}) || "₦0"
					}}
				</div>
			</div>
			<div
				class="card small cursor-pointer"
				@click="() => navigate('/borrowers')"
			>
				<div class="name">Total Collection Due</div>
				<div class="number">
					{{
						stats.total_amount_due?.toLocaleString("en-NG", {
							style: "currency",
							currency: "NGN"
						}) || "₦0"
					}}
				</div>
			</div>
			<div
				class="card big cursor-pointer"
				@click="() => navigate('/cancellations')"
			>
				<div class="name">Total No. of Terminated Loans</div>
				<div class="number">{{ stats.total_loans_terminated || 0 }}</div>
			</div>
			<div
				class="card big cursor-pointer"
				@click="() => navigate('/borrowers')"
			>
				<div class="name">Loan Volume Terminated</div>
				<div class="number">
					{{
						stats.total_amount_terminated?.toLocaleString("en-NG", {
							style: "currency",
							currency: "NGN"
						}) || "₦0"
					}}
				</div>
			</div>
			<div class="card big">
				<div class="name">% of Default</div>
				<div class="number">{{ stats.default_percentage || 0 }}</div>
			</div>
		</div>
		<div class="top-chart">
			<PieChart :data="productData" />
			<DoughnutChart :data="genderData" />
		</div>

		<BarChart :data="ageData" />
	</div>
	<vue-element-loading
		:active="show"
		:is-full-screen="true"
		background-color="rgba(255, 255, 255, .8)"
		spinner="bar-fade-scale"
	/>
</template>

<script>
import moment from "moment";
import { useStore } from "vuex";
import { ref, onMounted, computed } from "vue";
import { useRouter } from "vue-router";

import ActivityDate from "../components/activityDate.vue";
import DateFilter from "../components/dateFilter.vue";
import BarChart from "../components/charts/bar.vue";
import PieChart from "../components/charts/pie.vue";
import DoughnutChart from "../components/charts/doughnut.vue";

export default {
	name: "Dashboard",
	components: {
		DateFilter,
		ActivityDate,
		BarChart,
		PieChart,
		DoughnutChart
	},

	setup() {
		const showDate = ref(false);
		const store = useStore();
		const show = ref(true);
		const showCustomDate = ref(false);
		const ageChart = ref({});
		const productChart = ref({});
		const genderChart = ref({});
		const start_date = ref("");
		const end_date = ref("");
		const activityBox = ref(null);
		const router = useRouter();

		onMounted(async () => {
			await store.dispatch("fetchLoanStats");
			await store.dispatch("fetchAgeDistribution");
			await store.dispatch("fetchGenderDistribution");
			await store.dispatch("fetchProductDistribution");
			setAgeChart();
			setGenderChart();
			setProductChart();
			show.value = false;
		});

		const stats = computed(() => {
			return store.state.dashboardStats;
		});

		const ageData = computed(() => {
			return ageChart.value;
		});

		const genderData = computed(() => {
			return genderChart.value;
		});

		const productData = computed(() => {
			return productChart.value;
		});

		const setAgeChart = () => {
			const ageLabels = store.state.ageDistribution.map(g => {
				return g.age;
			});

			const ageCount = store.state.ageDistribution.map(g => {
				return g.count;
			});

			ageChart.value = {
				labels: ageLabels,
				datasets: [
					{
						label: "Age Data",
						backgroundColor: "#7F90B1",
						data: ageCount
					}
				]
			};
		};

		const setProductChart = () => {
			const productLabels = store.state.productDistribution.map(p => {
				return p.product.name;
			});
			const productCount = store.state.productDistribution.map(p => {
				return p.count;
			});

			productChart.value = {
				labels: productLabels || null,
				datasets: [
					{
						label: "Product Distribution",
						backgroundColor: ["#6BA1FF", "#4B89B3"],
						data: productCount
					}
				]
			};
		};

		const setGenderChart = () => {
			const genderLabels = store.state.genderDistribution.map(g => {
				return g.gender;
			});
			const genderCount = store.state.genderDistribution.map(g => {
				return g.count;
			});

			genderChart.value = {
				labels: genderLabels,
				datasets: [
					{
						label: "Product Distribution",
						backgroundColor: ["#6BA1FF", "#4B89B3"],
						data: genderCount
					}
				]
			};
		};

		const openActivityDate = () => {
			showDate.value = !showDate.value;
			showCustomDate.value = false;
		};

		const customDate = () => {
			showDate.value = false;
			showCustomDate.value = true;
		};

		const customFilter = async data => {
			show.value = true;

			await store.dispatch("filterDashboard", data);

			show.value = false;
		};

		const filterDate = async data => {
			show.value = true;

			if (data === "all") {
				await store.dispatch("filterDashboard", {
					start_date: "",
					end_date: ""
				});
			} else {
				end_date.value = moment().format("YYYY-MM-DD");

				start_date.value = moment()
					.subtract(+data, "days")
					.format("YYYY-MM-DD");

				await store.dispatch("filterDashboard", {
					start_date: start_date.value,
					end_date: end_date.value
				});

				setAgeChart();
				setGenderChart();
				setProductChart();
			}

			show.value = false;
		};

		const navigate = url => {
			router.push(url);
		};

		return {
			showDate,
			show,
			stats,
			filterDate,
			moment,
			ageChart,
			genderChart,
			productChart,
			ageData,
			genderData,
			productData,
			setAgeChart,
			setGenderChart,
			setProductChart,
			showCustomDate,
			customDate,
			customFilter,
			openActivityDate,
			end_date,
			start_date,
			activityBox,
			navigate
		};
	}
};
</script>

<style lang="scss" scoped>
.top-chart {
	display: flex;
	justify-content: space-between;
	margin: 100px 0;

	@include respond-to(tablet) {
		display: block;
	}
}

.dropdown {
	color: #49505d;
	border: 1px solid #bfc7d8;
	font-size: 12px;
	width: 130px;
	display: flex;
	justify-content: space-between;
	padding: 10px;

	svg,
	span {
		position: relative;
		top: 2px;
	}
}

.data-card {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	.card {
		display: grid;
		align-content: space-between;
		background: white;
		height: 150px;
		width: 24%;
		padding: 25px;
		border-radius: 5px;

		.name {
			color: #49505d;
			font-size: 13px;
		}
	}
	.card.big {
		width: 32.5%;
		margin-top: 20px;

		@include respond-to(tablet) {
			width: 100%;
		}
	}

	.card.small {
		@include respond-to(laptop) {
			width: 45%;
			margin-bottom: 20px;
		}

		@include respond-to(mobile) {
			width: 100%;
		}
	}

	.cursor-pointer {
		cursor: pointer;
	}
}
</style>
