<template>
  <div v-if="$store.state.manageRole === 1">
    <div class="role-util">
      <div>Manage Member Roles</div>
      <div
        v-if="$store.getters['auth/createRoles']"
        @click="$store.state.manageRole = 2"
      >
      <plus-icon size="16" stroke-width="2" />
        <span>Create Role</span>
      </div>
    </div>
    <div class="sessions">
      <span class="active-session">All Roles ({{ roles.length }})</span>
    </div>
    <div v-for="(role, index) in roles" :key="index">
      <div class="roles">
        <div class="head">
          <div>

            <svg
              @click="showRoleDetails(index, $event)"
              :ref="`getSVG${index}`"
              class="chevron"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 15L12 9L18 15"
                stroke="#6E737E"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {{ role.name }}
          </div>
          <div>
            <users-icon size="14"/>
            <span>Members with this role ({{ role.users.length }})</span>
          </div>
        </div>
        <div v-if="showRole && roleIndex === index">
          <div class="role-text">
            <div>
              {{ role.description }}
            </div>
          </div>
          <div class="permissions">
            <div v-for="(permission, index) in permissions" :key="index">
              <div class="permission">
                <Toggle
                  :inputValue="permission.id"
                  :modelValue="
                    permissionInRole(permission.id, role.permissions)
                  "
                  @change="onToggleRole($event, role.id, permission.id)"
                ></Toggle>
                <div class="role-id">
                  {{ role.id }}
                </div>
                <span>{{ permission.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="$store.state.manageRole === 2" class="custom-role">
    <NewRole />
  </div>
</template>

<script>
import { mapActions } from "vuex";

import NewRole from "./NewRole.vue";
import Toggle from "./toggleBar.vue";
export default {
  data() {
    return {
      showRole: false,
      roleIndex: "",
    };
  },
  components: {
    NewRole,
    Toggle,
  },
  methods: {
    ...mapActions({
      getRoles: "roles/getRoles",
      getPermissions: "roles/getPermissions",
      addPermission: "roles/addPermission",
      removePermission: "roles/removePermission",
    }),

    showRoleDetails(index, e) {
      const svg = this.$refs[`getSVG${index}`][0].classList.toggle("rotate");

      this.roleIndex = index;
      this.showRole = !this.showRole;
    },

    async onToggleRole(e, role_id, permission_id) {
      if (e.target.checked == true) {
        await this.addPermission({
          role_id: role_id.toString(),
          permission_id: permission_id.toString(),
        });
      }
      if (e.target.checked == false) {
        await this.removePermission({
          role_id: role_id.toString(),
          permission_id: permission_id.toString(),
        });
      }
    },

     permissionInRole(permission_id, role_permissions) {
      if (role_permissions?.filter((p) => p.id === permission_id).length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },

  async mounted() {
    await this.getRoles();
    await this.getPermissions();
  },

  computed: {
    roles() {
      return this.$store.state.roles.roles;
    },

    permissions() {
      return this.$store.state.roles.permissions;
    },
  },
};
</script>

<style lang="scss" scoped>
.role-util {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  font-size: 14px;
  div:nth-of-type(1) {
    color: #49505d;
  }
  div:nth-of-type(2) {
    cursor: pointer;
    span {
      position: relative;
      bottom: 4px;
      margin-left: 5px;
      color: #4c5c7d;
    }
  }
}
.roles {
  border: 2px solid #dfe3ec;
  border-radius: 5px;
  margin-bottom: 60px;
  .head {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    background: #f9f9f9;
    padding: 15px;
    border-bottom: 2px solid #dfe3ec;

    .chevron {
      display: inline-block;
      position: relative;
      transition: 0.8s;

      @include respond-to(mobile) {
        margin-right: 0;
      }
    }
    .rotate {
      transform: rotate(180deg);

      /* Safari */
      -webkit-transform: rotate(180deg);

      /* Firefox */
      -moz-transform: rotate(180deg);

      /* IE */
      -ms-transform: rotate(180deg);

      /* Opera */
      -o-transform: rotate(180deg);
    }
    div:nth-of-type(2) {
      font-size: 12px;
      position: relative;
      top: 3px;
      span {
        position: relative;
        top: 4px;
        color: #6e737e;

        @include respond-to(mobile) {
          top: 8px;
        }
      }
    }
    svg {
      margin-right: 10px;
      position: relative;
      top: 7px;

      @include respond-to(mobile) {
        display: none;
      }
    }
  }
  .role-text {
    color: #6e737e;
    font-size: 15px;
    padding: 20px 30px 0;
    div {
      border-bottom: 1px solid #dfe3ec;
      padding-bottom: 20px;
    }
  }
}
.permissions {
  margin-top: 30px;
  font-size: 14px;
  color: #49505d;
  padding: 0 40px;
  display: flex;
  flex-wrap: wrap;
  div {
    width: 50%;
    .permission {
      display: flex;
      width: 100%;
      margin-bottom: 35px;
      span {
        position: relative;
        top: 5px;
        left: 10px;
      }
      .role-id {
        display: none;
      }
    }
  }
}
</style>
