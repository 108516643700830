<template>
  <SideBar />

  <div class="body">
    <NavBar />
    <div class="directory">
      <span>Applications </span><span>/ Applicant Details</span>
    </div>

    <div class="dashboard-head">
      <div class="dashboard-title">
        {{ user?.first_name }} {{ user?.last_name }}
      </div>
      <div v-if="isExporting" class="export-loader" style="">
        <div><Spinner /></div>
      </div>
      <div v-else @click="downloadCSV" class="export">
        <download-icon size="15" stroke-width="3" />
        <span>Export</span>
      </div>
    </div>
    <KYC :user="user" />

    <UploadedFiles :user="user" />

    <div class="tabs">
      <div class="tab active">Current Loan Application</div>
    </div>
    <CurrentLoan
      :currentLoan="currentLoan"
      :applicationDefault="applicationDefault"
      :balanceLeft="balanceLeft"
      :maturityDate="maturityDate"
      :applicantEquity="applicantEquity"
      @cancelLoan="openPasswordModal"
    />
    <div class="tabs">
      <div @click="tab = 1" :class="[tab === 1 ? 'active' : ' ', 'tab']">
        Real time Loan Facility Information
      </div>
      <div @click="tab = 2" :class="[tab === 2 ? 'active' : ' ', 'tab']">
        Loan Facility Details
      </div>
      <div @click="tab = 3" :class="[tab === 3 ? 'active' : ' ', 'tab']">
        Transaction History
      </div>
      <div @click="tab = 4" :class="[tab === 4 ? 'active' : ' ', 'tab']">
        Terminated Loans
      </div>
      <div @click="tab = 5" :class="[tab === 5 ? 'active' : ' ', 'tab']">
        Credit Reports
      </div>
      <div @click="tab = 6" :class="[tab === 6 ? 'active' : ' ', 'tab']">
        Cards
      </div>
    </div>
    <LoanDetails
      :tab="tab"
      :cancelledLoans="cancelledLoans"
      :loans="loans"
      :paidLoans="paidLoans"
      :transactions="transactions"
      :currentLoan="currentLoan"
      :creditReport="currentLoan.user?.credit_data"
      :cards="currentLoan.user?.cards"
    />
  </div>
  <vue-element-loading
    :active="show"
    :is-full-screen="true"
    background-color="rgba(255, 255, 255, .8)"
    spinner="bar-fade-scale"
  />

  <!-- Password Modal -->
  <transition name="modal-fade">
    <div v-show="showPasswordModal" class="custom-password-modal">
      <div class="password-modal">
        <h6>Enter Password</h6>
        <input
          type="password"
          class="modal-password"
          v-model="userPassword"
          placeholder="Password"
        />
        <div>
          <!-- <button @click="closePasswordModal">Close</button> -->
          <button
            class="password-submit"
            role="button"
            @click="cancelPendingCharges"
          >
            <span>Submit</span>
          </button>
        </div>
      </div>
    </div>
  </transition>
  <div v-if="showModal" class="modal-overlay" @click="closeModal"></div>
  <div
    v-if="showPasswordModal"
    class="modal-overlay"
    @click="closePasswordModal"
  ></div>
</template>

<script>
import { mapActions } from "vuex";

import CSV from "../utils/CSV/downloadCSV";
import KYC from "../components/lib/ApplicantDetails/KYC.vue";
import CurrentLoan from "../components/lib/ApplicantDetails/CurrentLoan.vue";
import LoanDetails from "../components/lib/ApplicantDetails/LoanDetails.vue";
import UploadedFiles from "../components/lib/ApplicantDetails/UploadedFiles.vue";

export default {
  name: "ApplicantDetails",
  components: {
    KYC,
    CurrentLoan,
    LoanDetails,
    UploadedFiles,
  },

  data() {
    return {
      transactionHistory: "",
      isExporting: false,
      showLoanOverview: true,
      show: false,
      tab: 1,
      userId: this.$route.query.user_id,
      show: true,
      userPassword: "",
      showPasswordModal: false,
    };
  },

  async mounted() {
    await this.fetchLoan({ id: this.$route.params.id });
    await this.fetchApplicantLoans({ userId: this.userId });
    await this.fetchApplicantDetails({ userId: this.userId });
    await this.fetchApplicantTransactions({ userId: this.userId });
    await this.currentApplicationDefault();
    await this.fetchApplicantEquity({ userId: this.userId });
    this.show = false;
  },

  methods: {
    ...mapActions({
      fetchLoan: "loan/fetchLoan",
      fetchApplicantLoans: "loan/fetchApplicantLoans",
      fetchApplicantDetails: "loan/fetchApplicantDetails",
      cancelLoanCharges: "loan/cancelPendingDebits",
      fetchApplicantEquity: "transaction/fetchApplicantEquity",
      fetchApplicantTransactions: "transaction/fetchApplicantTransactions",
    }),

    async cancelPendingCharges() {
      this.show = true;

      await this.cancelLoanCharges({
        loan_id: this.currentLoan.id,
        password: this.userPassword,
      });

      this.show = false;
      this.showPasswordModal = false;
      this.closePasswordModal();
    },

    closePasswordModal() {
      this.showPasswordModal = false;
      this.userPassword = "";
    },

    openPasswordModal() {
      this.showPasswordModal = true;
    },

    async downloadCSV() {
      this.isExporting = true;

      const data = [
        {
          customer: `${this.user?.first_name} ${this.user?.last_name}`,
          email: this.user?.email,
          phone: this.user?.phone_number,
          gender: this.user?.gender,
          BVN: this.user?.bvn,
          DOB: this.moment(this.user?.dob).format("MMM Do YYYY"),
          "marital status": this.user?.marital_status.value,
          "House Address": this.user?.house_address,
          "Employment Status": this.user?.employment_status?.value,
          employer: this.user?.employer,
          salary: this.user?.income_band?.value,
          "management fee": this.currentLoan?.total_fee?.toLocaleString(
            "en-NG",
            {
              style: "currency",
              currency: "NGN",
            }
          ),
        },
      ];

      const dataHead = [
        "Cutomer",
        "Email",
        "Amount loaned",
        "Date Issued",
        "Maturity Date",
      ];

      CSV("loans details", data, dataHead);

      this.isExporting = false;
    },

    currentApplicationDefault() {
      const schedule = this.$store.state.loan.loanDetails.schedule;
      const d = schedule?.filter((s) => s.status == "past_due")[0];
      const balance = schedule?.filter((b) => b.status !== "paid");
      this.maturityDate = schedule[schedule?.length - 1]?.due_date;
      this.balanceLeft = balance[0]?.outstanding_loan_balance;
      this.applicationDefault = d?.default;
    },
  },

  computed: {
    transactions() {
      return this.$store.state.transaction?.transactionDetails;
    },

    loans() {
      return this.$store.state.loan?.loans;
    },

    currentLoan() {
      return this.$store.state.loan?.loanDetails;
    },

    user() {
      return this.$store.state.loan?.applicantDetails;
    },

    paidLoans() {
      return this.$store.state.loan?.applicantPaidLoans;
    },

    cancelledLoans() {
      return this.$store.state.loan?.applicantCancelledLoans;
    },

    creditData() {
      return this.currentLoan?.user?.credit_data.map((item) => {
        item.includes(item.keys("report"));
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  .tabs {
    display: flex;
    font-size: 13px;
    color: #92969e;
    margin: 50px 0 30px;
    cursor: pointer;

    .tab {
      margin-right: 20px;
    }

    .tab.active {
      padding-bottom: 10px;
      border-bottom: 2px solid #d4a668;
      color: #49505d;
    }
  }

  .tabs:nth-of-type(2) {
    margin-bottom: 0;
  }
}

.custom-password-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 1000;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.3s;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

input.modal-password {
  background: transparent;
  font-size: 13px;
  color: #252d3d;
  font-weight: 400;
  border: solid 1px #bfc7d8;
  border-radius: 4px;
  padding: 8px;
  max-width: 80vw;
  margin-bottom: 18px;
  width: 360px;
}

button.password-submit {
  background-color: #252d3d;
  border: none;
  border-radius: 4px;
  display: block;
  margin-left: auto;

  span {
    font-weight: 500;
    color: white;
    font-size: 13px;
    padding: 14px;
  }
}
</style>
